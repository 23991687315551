export default {
  props: {
    params: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  watch: {
    'params.applyTime'(newValue, oldValue) {
      if (newValue?.length) {
        this.params.startApplyTime = newValue[0] + ' 00:00:00'
        this.params.endApplyTime = newValue[1] + ' 23:59:59'
      } else {
        this.params.startApplyTime = undefined
        this.params.endApplyTime = undefined
      }
    },
    'params.orderTime'(newValue, oldValue) {
      if (newValue?.length) {
        this.params.startOrderTime = newValue[0] + ' 00:00:00'
        this.params.endOrderTime = newValue[1] + ' 23:59:59'
      } else {
        this.params.startOrderTime = undefined
        this.params.endOrderTime = undefined
      }
    },
  }
}
