//备机、配件申请筛选表单
<template>
  <span>
    <el-form-item label="申请单号">
      <el-input v-model="params.orderCode" clearable placeholder="请输入" />
    </el-form-item>
	<el-form-item label="U8单号">
	  <el-input v-model="params.u8AllotOrderCode" clearable placeholder="请输入" />
	</el-form-item>
	<el-form-item label="申请人">
	  <el-input v-model="params.proposerName" clearable placeholder="请输入" />
	</el-form-item>
	<el-form-item label="日期">
      <el-date-picker
        v-model="params.applyTime"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        unlink-panels
      ></el-date-picker>
    </el-form-item>
    <el-form-item label="客户名称">
      <el-input v-model="params.clientName" clearable placeholder="请输入" />
    </el-form-item>
    <el-form-item label="收货人">
      <el-input v-model="params.consignee" clearable placeholder="请输入" />
    </el-form-item>
    <el-form-item label="联系电话">
      <el-input
        v-model="params.consigneeMobile"
        clearable
        placeholder="请输入"
      />
    </el-form-item>
    <el-form-item label="料号">
      <el-input v-model="params.code" clearable placeholder="请输入" />
    </el-form-item>
    <el-form-item label="物品名称">
      <el-input v-model="params.name" clearable placeholder="请输入" />
    </el-form-item>
	<el-form-item label="审核状态">
	  <el-select v-model="params.auditStatus" clearable placeholder="请选择">
	    <el-option
	      v-for="item in $constant.auditStatus"
	      :key="item.value"
	      :label="item.label"
	      :value="item.value"
	    />
	  </el-select>
	</el-form-item>
  </span>
</template>

<script>
import formTypeMixins from './formTypeMixins.js'
export default {
  mixins: [formTypeMixins]
}
</script>
