export default {
  props: {
    tableData: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  filters: {
    commonFilter: function (value, list = []) {
      let name = list.find(a => a.value === value)?.label || '-'
      return name
    }
  },
  methods: {
    toDetail({ orderCode = '', orderType = '' }) {
      this.$router.push({
        path: '/myAudit/detail',
        query: {
          orderCode,
          type: orderType,
          from: 'orderQuery'
        }
      })
    }
  }
}
