//设备形态转换表格
<template>
  <el-table border :data="tableData" height="100%">
    <el-table-column align="center" label="序号" type="index" width="60" />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      prop="orderCode"
      label="申请单号"
      width="180"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      label="U8单号"
      width="150"
      :formatter="({ u8OrderCode }) => u8OrderCode || '-'"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      prop="proposerName"
      label="申请人"
      width="100"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      prop="applyDepartmentName"
      label="申请部门"
      width="100"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      label="日期"
      width="120"
    >
      <template slot-scope="{ row }">
        {{ row.orderTime | date('YYYY-mm-dd') }}
      </template>
    </el-table-column>
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      prop="code"
      label="转换前/转换后料号"
      width="150"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      prop="name"
      label="转换前/转换后物品名称"
      width="170"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      label="转换前物品sn"
      width="120"
      :formatter="({ oldSn }) => oldSn || '-'"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      label="转换后物品sn"
      width="120"
      :formatter="({ newSn }) => newSn || '-'"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      prop="auditStatusStr"
      label="审批状态"
    />
    <el-table-column
      header-align="center"
      show-overflow-tooltip
      label="操作"
      width="60"
      fixed="right"
    >
      <template slot-scope="{ row }">
        <el-button
          size="medium"
          type="text"
          @click="toDetail({ ...row, orderType: 5 })"
        >
          详情
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import tableTypeMixins from './tableTypeMixins.js'
export default {
  mixins: [tableTypeMixins]
}
</script>

<style lang="scss" scoped></style>
