//发票申请筛选表单
<template>
  <span>
    <el-form-item label="申请单号">
      <el-input v-model="params.orderCode" clearable placeholder="请输入" />
    </el-form-item>
	<el-form-item label="申请人">
	  <el-input v-model="params.proposerName" clearable placeholder="请输入" />
	</el-form-item>
	<el-form-item label="客户名称">
      <el-input v-model="params.clientName" clearable placeholder="请输入" />
    </el-form-item>
    <el-form-item label="电话号码">
      <el-input v-model="params.clientPhone" clearable placeholder="请输入" />
    </el-form-item>
    <el-form-item label="料号">
      <el-input v-model="params.code" clearable placeholder="请输入" />
    </el-form-item>
    <el-form-item label="物品名称">
      <el-input v-model="params.name" clearable placeholder="请输入" />
    </el-form-item>
    <el-form-item label="单价">
      <el-input v-model="params.price" clearable placeholder="请输入" />
    </el-form-item>
    <el-form-item label="总价">
      <el-input v-model="params.amount" clearable placeholder="请输入" />
    </el-form-item>
	<el-form-item label="审核状态">
	  <el-select v-model="params.auditStatus" clearable placeholder="请选择">
	    <el-option
	      v-for="item in $constant.auditStatus"
	      :key="item.value"
	      :label="item.label"
	      :value="item.value"
	    />
	  </el-select>
	</el-form-item>
  </span>
</template>

<script>
import formTypeMixins from './formTypeMixins.js'
export default {
  mixins: [formTypeMixins]
}
</script>
