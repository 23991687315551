//发票申请表格
<template>
  <el-table border :data="tableData" height="100%">
    <el-table-column align="center" label="序号" type="index" width="60" />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      prop="orderCode"
      label="申请单号"
      width="180"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      label="关联服务收费单"
      width="180"
      :formatter="({ relationOrderCode }) => relationOrderCode || '-'"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      prop="proposerName"
      label="申请人"
      width="100"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      prop="applyDepartmentName"
      label="申请部门"
      width="100"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      label="发票票种"
      width="100"
    >
      <template slot-scope="{ row }">
        {{ row.invoiceType | commonFilter($constant.invoiceType) }}
      </template>
    </el-table-column>
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      prop="clientName"
      label="客户名称"
      width="120"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      label="客户税号"
      width="120"
      :formatter="({ clientNo }) => clientNo || '-'"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      label="电话号码"
      width="120"
      :formatter="({ clientPhone }) => clientPhone || '-'"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      label="开户银行"
      width="120"
      :formatter="({ clientBank }) => clientBank || '-'"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      label="银行账户"
      width="120"
      :formatter="({ clientBankNo }) => clientBankNo || '-'"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      label="单价"
      :formatter="({ price }) => price / 100"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      prop="num"
      label="数量"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      label="总价"
      :formatter="({ amount }) => amount / 100"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      prop="auditStatusStr"
      label="审批状态"
    />
    <el-table-column
      header-align="center"
      show-overflow-tooltip
      label="操作"
      width="60"
      fixed="right"
    >
      <template slot-scope="{ row }">
        <el-button
          size="medium"
          type="text"
          @click="toDetail({ ...row, orderType: 8 })"
        >
          详情
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import tableTypeMixins from './tableTypeMixins.js'
export default {
  mixins: [tableTypeMixins]
}
</script>
