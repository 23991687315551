//业务查询
<template>
  <Page tableNeedFixed :showMoreFilter="typeof params.type === 'number'">
    <template slot="search">
      <el-form inline :model="params">
        <el-form-item label="业务类型" prop="type">
          <el-select
            v-model="params.type"
            clearable
            placeholder="请选择"
            @change="typeChange"
          >
            <el-option
              v-for="item in $constant.applyType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <FormType0 :params="params" v-if="params.type === 0" />
        <FormType1 :params="params" v-else-if="[1, 2].includes(params.type)" />
        <FormType3 :params="params" v-else-if="params.type === 3" />
        <FormType4 :params="params" v-else-if="params.type === 4" />
        <FormType5 :params="params" v-else-if="params.type === 5" />
        <FormType6 :params="params" v-else-if="params.type === 6" />
        <FormType7 :params="params" v-else-if="params.type === 7" />
        <FormType8 :params="params" v-else-if="params.type === 8" />
      </el-form>
    </template>
    <div class="table-wrap" v-loading="loading">
      <TableType0 :tableData="tableData" v-if="params.type === 0" />
      <TableType1
        :tableData="tableData"
        :orderType="params.type"
        v-else-if="[1, 2].includes(params.type)"
      />
      <TableType3 :tableData="tableData" v-else-if="params.type === 3" />
      <TableType4 :tableData="tableData" v-else-if="params.type === 4" />
      <TableType5 :tableData="tableData" v-else-if="params.type === 5" />
      <TableType6 :tableData="tableData" v-else-if="params.type === 6" />
      <TableType7 :tableData="tableData" v-else-if="params.type === 7" />
      <TableType8 :tableData="tableData" v-else-if="params.type === 8" />
      <el-table
        border
        :data="[]"
        style="width: 100%"
        height="100%"
        v-else
      ></el-table>
    </div>
    <Pagination
      :total="total"
      :page-size.sync="params.pageSize"
      :page.sync="params.pageNo"
      @change="getData"
    />
  </Page>
</template>

<script>
import watchParamsGetData from '../../mixins/watchParamsGetData'
import FormType0 from './components/FormType0.vue'
import FormType1 from './components/FormType1.vue'
import FormType3 from './components/FormType3.vue'
import FormType4 from './components/FormType4.vue'
import FormType5 from './components/FormType5.vue'
import FormType6 from './components/FormType6.vue'
import FormType7 from './components/FormType7.vue'
import FormType8 from './components/FormType8.vue'
import TableType0 from './components/TableType0.vue'
import TableType1 from './components/TableType1.vue'
import TableType3 from './components/TableType3.vue'
import TableType4 from './components/TableType4.vue'
import TableType5 from './components/TableType5.vue'
import TableType6 from './components/TableType6.vue'
import TableType7 from './components/TableType7.vue'
import TableType8 from './components/TableType8.vue'
export default {
  mixins: [watchParamsGetData],
  components: {
    FormType0,
    FormType1,
    FormType3,
    FormType4,
    FormType5,
    FormType6,
    FormType7,
    FormType8,
    TableType0,
    TableType1,
    TableType3,
    TableType4,
    TableType5,
    TableType6,
    TableType7,
    TableType8
  },
  data() {
    return {
      count: 0
    }
  },
  methods: {
    typeChange() {
      this.params = {
        type: this.params.type,
        pageNo: 1,
        pageSize: this.params.pageSize
      }
      this.tableData = []
      this.total = 0
      this.getData()
    },
    clearParams() {
      this.params = {
        type: this.params.type,
        pageNo: 1,
        pageSize: this.params.pageSize
      }
      this.getData()
    },
    async getData() {
      if (typeof this.params.type !== 'number') {
        this.count > 0 &&
          this.$message({ message: '请先选择业务类型', type: 'warning' })
        this.count++
        return
      }
      try {
        this.loading = true
        let params = this.$utils.deepClone(this.params)
        params.pageNum = params.pageNo
        delete params.pageNo
        delete params.applyTime
        delete params.orderTime
		//服务收费单金额处理
		if (params.type == 4) {
		  params.totalAmount = params.totalAmount ? params.totalAmount * 100 : undefined
		}
        //发票金额处理
		if (params.type == 8) {
          params.price = params.price ? params.price * 100 : undefined
          params.amount = params.amount ? params.amount * 100 : undefined
        }
        const { total, list } = await this.$api.apply.getOrder(params)
        this.tableData = (list || []).map(a => {
          const auditStatusStr =
            this.$constant.auditStatus.find(_ => _.value == a.auditStatus)
              ?.label || '-'
          return { ...a, auditStatusStr }
        })
        this.total = total
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
