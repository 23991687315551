//其他入库表格
<template>
  <el-table border :data="tableData" height="100%">
    <el-table-column align="center" label="序号" type="index" width="60" />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      prop="orderCode"
      label="申请单号"
      width="180"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      label="U8单号"
      width="150"
      :formatter="({ u8OrderCode }) => u8OrderCode || '-'"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      label="入库日期"
      width="120"
    >
      <template slot-scope="{ row }">
        {{ row.orderTime | date('YYYY-mm-dd') }}
      </template>
    </el-table-column>
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      prop="storageName"
      label="入库类别"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      prop="code"
      label="存货编码"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      prop="name"
      label="存货名称"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      label="序列号"
      :formatter="({ sn }) => sn || '-'"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      prop="auditStatusStr"
      label="审批状态"
    />
    <el-table-column
      header-align="center"
      show-overflow-tooltip
      label="操作"
      width="60"
      fixed="right"
    >
      <template slot-scope="{ row }">
        <el-button
          size="medium"
          type="text"
          @click="toDetail({ ...row, orderType: 6 })"
        >
          详情
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import tableTypeMixins from './tableTypeMixins.js'
export default {
  mixins: [tableTypeMixins]
}
</script>
