//服务配件归还表格
<template>
  <el-table border :data="tableData" height="100%">
    <el-table-column align="center" label="序号" type="index" width="60" />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      prop="orderCode"
      label="申请单号"
      width="180"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      label="U8单号"
      width="150"
      :formatter="({ u8AllotOrderCode }) => u8AllotOrderCode || '-'"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      prop="proposerName"
      label="申请人"
      width="100"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      prop="applyDepartmentName"
      label="申请部门"
      width="100"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      label="申请时间"
      width="120"
    >
      <template slot-scope="{ row }">
        {{ row.applyTime | date('YYYY-mm-dd') }}
      </template>
    </el-table-column>
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      prop="trackingNumber"
      label="物流单号"
      width="120"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      label="关联申请单"
      width="120"
      :formatter="({ applyCode }) => applyCode || '-'"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      label="用途"
      :formatter="({ purpose }) => purpose || '-'"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      prop="code"
      label="料号"
      width="120"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      prop="name"
      label="物品名称"
      width="120"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      label="主机sn"
      width="120"
      :formatter="({ completeSn }) => completeSn || '-'"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      label="配件sn"
      width="120"
      :formatter="({ sn }) => sn || '-'"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      prop="clientName"
      label="客户"
      width="120"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      label="故障时间"
      width="120"
    >
      <template slot-scope="{ row }">
        {{ row.malfunctionTime | date('YYYY-mm-dd') }}
      </template>
    </el-table-column>
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      label="故障描述"
      width="120"
      :formatter="({ malfunctionDesc }) => malfunctionDesc || '-'"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      prop="auditStatusStr"
      label="审批状态"
    />
    <el-table-column
      header-align="center"
      show-overflow-tooltip
      label="操作"
      width="60"
      fixed="right"
    >
      <template slot-scope="{ row }">
        <el-button
          size="medium"
          type="text"
          @click="toDetail({ ...row, orderType: 3 })"
        >
          详情
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import tableTypeMixins from './tableTypeMixins.js'
export default {
  mixins: [tableTypeMixins]
}
</script>
